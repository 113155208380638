<template>
  <div>
    <Loader :loading="isLoader" v-if="isLoader" />
    <div v-else>
      <div class="flex flex-row pb-4 items-center">
        <label class="label w-1/5 font-bold text-right pr-4"
          >User Name:<span class="text-red-600">*</span></label
        >
        <div class="pl-2 relative w-3/4">
          <input
            type="text"
            v-model="user.UserName"
            :disabled="user.isDiabled"
          />
        </div>
      </div>

      <div class="flex flex-row pb-4 items-center">
        <label class="label w-1/5 font-bold text-right pr-4"
          >User Email:<span class="text-red-600">*</span></label
        >
        <div class="pl-2 relative w-3/4">
          <input
            type="text"
            v-model="user.UserEmail"
            :disabled="user.isDiabled"
            @blur="validateEmail($event.target)"
          />
        </div>
      </div>

      <div class="flex flex-row pb-4 items-center">
        <label class="label w-1/5 font-bold text-right pr-4"
          >Role:<span class="text-red-600">*</span></label
        >
        <div class="select-box pl-2 relative w-3/4">
          <AutoCompleteDropdown
            :options="roles"
            v-model="user.selectedRole"
            @selected-item="setSelectedRole"
            :limit="limit"
            :isEditData="user.selectedRole"
          />
        </div>
      </div>

      <div class="flex flex-row pb-4 items-center">
        <label class="label w-1/5 font-bold text-right pr-4"
          >Country:<span class="text-red-600">*</span></label
        >
        <div class="select-box pl-2 relative w-3/4">
          <AutoCompleteDropdown
            :options="countries"
            v-model="user.selectedCountry"
            @selected-item="setSelectedCountry"
            :apiPath="apiCountriesPath"
            multiple
            :isEditData="user.selectedCountry"
          />
        </div>
      </div>

      <div class="flex flex-row pb-4 items-center">
        <label class="label w-1/5 font-bold text-right pr-4"
          >Status:<span class="text-red-600">*</span></label
        >
        <div class="select-box pl-2 relative w-3/4">
          <AutoCompleteDropdown
            :options="status"
            v-model="user.selectedStatus"
            @selected-item="setSelectedStatus"
            :isEditData="user.selectedStatus"
          />
        </div>
      </div>
    </div>
    <!--Buttons-->
    <div class="popup-action__button">
      <Button type="primary" @click="save()" :disabled="isSaveEnable"
        ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            d="M0 2C0 .9.9 0 2 0h14l4 4v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5 0v6h10V2H5zm6 1h3v4h-3V3z"
          />
        </svg>
        Save</Button
      >
    </div>
  </div>
</template>

<script>
import userService from "../../services/uam";
import loggerService from "../../services/logger";
import { mapGetters, mapMutations } from "vuex";
import authentication from "../../authentication";

export default {
  name: "Title",
  components: {
    Button: () => import(/* webpackPrefetch: true */ "./../common/Button"),
    Loader: () => import(/* webpackPrefetch: true */ "./../common/Loader"),
    AutoCompleteDropdown: () =>
      import(/* webpackPrefetch: true */ "./../common/AutoCompleteDropdown"),
  },
  data: () => ({
    isLoader: true,
    limit: 1,
    countries: [],
    roles: [],
    status: [
      { Id: 1, Value: "Active" },
      { Id: 2, Value: "Inactive" },
    ],
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
      this.getRoles();
    }, 800);
  },
  computed: {
    ...mapGetters({ user: "GET_USER_DETAILS" }),
    isSaveEnable() {
      return this.user.UserName != null &&
        this.user.UserEmail != null &&
        this.user.selectedCountry.length !== 0 &&
        this.user.selectedRole != null &&
        this.user.selectedStatus != null
        ? false
        : true;
    },
    apiCountriesPath() {
      return `FormulationMapping/GetAllCountries`;
    },
  },
  methods: {
    ...mapMutations({ setUser: "SET_USER_DETAILS" }),
    async getRoles() {
      try {
        const { data } = await userService.getRoles();
        this.roles = data;
      } catch (error) {
        loggerService.logError(error);
        this.countryCategoryData = [];
      }
    },
    async save() {
      const user = {
        UserName: this.user.UserName,
        UserEmail: this.user.UserEmail,
        UserId: this.user.UserId,
        RoleId: this.user.RoleId,
        ActiveCountries: this.user.selectedCountry.toString(),
        AdminEmail: authentication.getUserEmail(),
        Active: this.user.selectedStatus === "Active" ? true : false,
        IsCreateUser: this.user.IsCreateUser,
      };
      try {
        const { data } = await userService.addOrUpdateUser(user);
        if (data === "Error occurred. Please contact the admin") {
          return;
        }
        if (data === "This Email already Exists.") {
          loggerService.logError("This Email already Exists");
          return;
        }
        if (user.IsCreateUser) {
          loggerService.logSuccess("User Created Successfully");
        } else {
          loggerService.logSuccess("User Updated Successfully");
        }
        this.$emit("on-close");
      } catch (error) {
        loggerService.logError(error);
      }
      this.setUser(user);
    },
    setSelectedRole(r) {
      if (r === true) {
        this.user.selectedRole = null;
        this.user.RoleId = null;
      } else {
        this.user.selectedRole = r.Value;
        this.user.RoleId = r.Id;
      }
    },
    setSelectedCountry(c) {
      if (c === true) {
        this.user.selectedCountry = null;
      } else {
        this.user.selectedCountry = c.map((i) => {
          return i.Id;
        });
      }
    },
    setSelectedStatus(s) {
      if (s === true) {
        this.user.selectedStatus = null;
      } else {
        this.user.selectedStatus = s.Value;
      }
    },
    validateEmail(event) {
      if (event.value.indexOf("@") === -1 || event.value.indexOf(".") === -1) {
        loggerService.logError("Enter Valid Email");
      }
    },
  },
};
</script>

<style scoped>
input {
  border: 1px solid #d2d6de !important;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
  background: #fff;
  height: 30px;
  opacity: 0.8;
  width: 100%;
}
</style>
