var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", [
            _c("div", { staticClass: "flex flex-row pb-4 items-center" }, [
              _c(
                "label",
                { staticClass: "label w-1/5 font-bold text-right pr-4" },
                [
                  _vm._v("User Name:"),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]
              ),
              _c("div", { staticClass: "pl-2 relative w-3/4" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.UserName,
                      expression: "user.UserName"
                    }
                  ],
                  attrs: { type: "text", disabled: _vm.user.isDiabled },
                  domProps: { value: _vm.user.UserName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "UserName", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "flex flex-row pb-4 items-center" }, [
              _c(
                "label",
                { staticClass: "label w-1/5 font-bold text-right pr-4" },
                [
                  _vm._v("User Email:"),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]
              ),
              _c("div", { staticClass: "pl-2 relative w-3/4" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.UserEmail,
                      expression: "user.UserEmail"
                    }
                  ],
                  attrs: { type: "text", disabled: _vm.user.isDiabled },
                  domProps: { value: _vm.user.UserEmail },
                  on: {
                    blur: function($event) {
                      return _vm.validateEmail($event.target)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "UserEmail", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "flex flex-row pb-4 items-center" }, [
              _c(
                "label",
                { staticClass: "label w-1/5 font-bold text-right pr-4" },
                [
                  _vm._v("Role:"),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]
              ),
              _c(
                "div",
                { staticClass: "select-box pl-2 relative w-3/4" },
                [
                  _c("AutoCompleteDropdown", {
                    attrs: {
                      options: _vm.roles,
                      limit: _vm.limit,
                      isEditData: _vm.user.selectedRole
                    },
                    on: { "selected-item": _vm.setSelectedRole },
                    model: {
                      value: _vm.user.selectedRole,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "selectedRole", $$v)
                      },
                      expression: "user.selectedRole"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "flex flex-row pb-4 items-center" }, [
              _c(
                "label",
                { staticClass: "label w-1/5 font-bold text-right pr-4" },
                [
                  _vm._v("Country:"),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]
              ),
              _c(
                "div",
                { staticClass: "select-box pl-2 relative w-3/4" },
                [
                  _c("AutoCompleteDropdown", {
                    attrs: {
                      options: _vm.countries,
                      apiPath: _vm.apiCountriesPath,
                      multiple: "",
                      isEditData: _vm.user.selectedCountry
                    },
                    on: { "selected-item": _vm.setSelectedCountry },
                    model: {
                      value: _vm.user.selectedCountry,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "selectedCountry", $$v)
                      },
                      expression: "user.selectedCountry"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "flex flex-row pb-4 items-center" }, [
              _c(
                "label",
                { staticClass: "label w-1/5 font-bold text-right pr-4" },
                [
                  _vm._v("Status:"),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]
              ),
              _c(
                "div",
                { staticClass: "select-box pl-2 relative w-3/4" },
                [
                  _c("AutoCompleteDropdown", {
                    attrs: {
                      options: _vm.status,
                      isEditData: _vm.user.selectedStatus
                    },
                    on: { "selected-item": _vm.setSelectedStatus },
                    model: {
                      value: _vm.user.selectedStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "selectedStatus", $$v)
                      },
                      expression: "user.selectedStatus"
                    }
                  })
                ],
                1
              )
            ])
          ]),
      _c(
        "div",
        { staticClass: "popup-action__button" },
        [
          _c(
            "Button",
            {
              attrs: { type: "primary", disabled: _vm.isSaveEnable },
              on: {
                click: function($event) {
                  return _vm.save()
                }
              }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 20 20"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M0 2C0 .9.9 0 2 0h14l4 4v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5 0v6h10V2H5zm6 1h3v4h-3V3z"
                    }
                  })
                ]
              ),
              _vm._v(" Save")
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }